<template>
  <GeneralLayout>
    <template v-slot:header> <GeneralHeader :title="''" /></template>
    <template v-slot:main> <NotFoundContainer /> </template>
  </GeneralLayout>
</template>

<script lang="ts">
import GeneralHeader from '../../components/base/GeneralHeader.vue';
import GeneralLayout from '../../components/layout/GeneralLayout.vue';
import NotFoundContainer from '../../components/tests/not-found/NotFoundContainer.vue';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: { NotFoundContainer, GeneralLayout, GeneralHeader },
})
export default class Start extends Vue {}
</script>
