<template>
  <GeneralLayout>
    <template v-slot:header> <GeneralHeader :title="name" /></template>
    <template v-slot:main>
      <WelcomeContainer />
    </template>
  </GeneralLayout>
</template>

<script lang="ts">
import GeneralHeader from '../../components/base/GeneralHeader.vue';
import GeneralLayout from '../../components/layout/GeneralLayout.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import WelcomeContainer from '../../components/tests/welcome/WelcomeContainer.vue';
import UserModule from '@/store/modules/UserModule';
import { getModule } from 'vuex-module-decorators';

@Component({
  components: { WelcomeContainer, GeneralLayout, GeneralHeader },
})
export default class Start extends Vue {
  userModule: UserModule = getModule(UserModule, this.$store);

  loading = false;

  get firstname(): string {
    return this.userModule.currentUser?.firstname ?? '';
  }

  get page(): string {
    return this.$route.path;
  }

  get name(): string {
    return 'Hallo!';
    // return this.page === '/start' ? `Hallo, ${this.firstname}!` : 'Hallo!';
  }
}
</script>
