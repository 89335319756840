<template>
  <GeneralLayout>
    <template v-slot:header>
      <GeneralHeader :title="title" :subtitle="subtitle"
    /></template>
    <template v-slot:main>
      <v-col
        cols="8"
        class="d-flex flex-column justify-center align-center align-self-center"
      >
        <v-col cols="10" sm="5" md="4" lg="3" class="d-flex justify-center">
          <v-img :src="leo" class="mx-auto" />
        </v-col>
        <p class="text-h4 white--text myHeadline text-center">
          {{ $t('root.title') }}
        </p>
        <p class="text-h6 text-md-h5 white--text myHeadline text-center">
          {{ $t('root.info') }}
        </p>
        <p class="text-h6 text-md-h5 white--text myHeadline text-center">
          {{ $t('root.info2') }}
        </p>
        <p class="text-h6 text-md-h5 white--text myHeadline text-center">
          {{ $t('root.link') }}
        </p>
        <p class="text-h6 text-md-h5 white--text myHeadline text-center">
          {{ $t('root.link2') }}
        </p>
      </v-col>
    </template>
  </GeneralLayout>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import GeneralHeader from '../../components/base/GeneralHeader.vue';
import GeneralLayout from '../../components/layout/GeneralLayout.vue';

@Component({
  components: {
    GeneralLayout,
    GeneralHeader,
  },
})
export default class Login extends Vue {
  get title(): string {
    return 'LEO';
  }

  get subtitle(): string {
    return 'Für lesestarke Kids!';
  }

  get leo(): string {
    return require('@/assets/images/lion_small.png');
  }
}
</script>
