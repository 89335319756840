<template>
  <GeneralLayout>
    <template v-slot:header>
      <GeneralHeader :title="$t('parent.info')"
    /></template>
    <template v-slot:main>
      <v-progress-circular
        v-if="loading"
        class="center-component"
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
      <v-col
        class="parent__content mt-4"
        align="center"
        cols="12"
        sm="10"
        md="9"
        lg="7"
        xl="6"
      >
        <v-col cols="12" class="parent__student">
          <v-card rounded class="mb-4" color="#FDFAF7">
            <v-row class="parent__row ma-0 d-flex flex-wrap">
              <v-col
                cols="12"
                sm="12"
                md="6"
                class="d-flex flex-column align-center"
              >
                <p class="ma-0 text-left button text-uppercase">
                  {{ $t('parent.student') }}
                </p>
                <p class="text-h6 ma-0 pl-1">
                  {{ user.firstname }} {{ user.lastname }}
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="3" class="">
                <template v-if="enabledA">
                  <p class="ma-0 button pa-0">{{ $t('teacher.formA') }}</p>
                  <v-icon v-if="!doneA" class="mt-1">mdi-close</v-icon>
                  <EvaluationButton v-else :student="user" form="a" />
                </template>
              </v-col>
              <v-col cols="12" sm="12" md="3" class="">
                <template v-if="enabledB">
                  <p class="ma-0 button pa-0">{{ $t('teacher.formB') }}</p>
                  <v-icon v-if="!doneB" class="mt-1">mdi-close</v-icon>
                  <EvaluationButton v-else :student="user" form="b" />
                </template>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <!-- class="d-flex flex-column justify-start align-center align-self-center" -->
        <v-col cols="12" class="d-flex flex-column justify-start text-left">
          <ol>
            <li v-for="n in 5" :key="n" class="white--text d-flex align-start">
              <v-icon class="cardinal-icon mr-3" large color="#ead148">
                {{ `mdi-numeric-${n}-circle-outline` }}
              </v-icon>
              <p
                class="text-h6 white--text myHeadline"
                v-html="$t(`parent.test.${n}`)"
              ></p>
            </li>
          </ol>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-btn
            id="next-btn"
            rounded
            color="#ead148"
            @click="goToStart"
            :disabled="noTestEnabled || hasFinishedEnabledTest"
            block
            class="py-4"
          >
            <p
              class="ma-0 text-h5 text-sm-h5 text-md-h5 text-lg-h5 text-xl-h5 norddruck"
            >
              {{
                hasFinishedEnabledTest
                  ? $t('parent.tests-done')
                  : $t('leo-start')
              }}
            </p>
          </v-btn>
        </v-col>
        <AdminSnackbar />
      </v-col>
    </template>
  </GeneralLayout>
</template>

<script lang="ts">
import AdminSnackbar from '../../components/admin/base/AdminSnackbar.vue';
import EvaluationButton from '../../components/admin/students/table/EvaluationButton.vue';
import GeneralHeader from '../../components/base/GeneralHeader.vue';
import GeneralLayout from '../../components/layout/GeneralLayout.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import UserModule from '@/store/modules/UserModule';
import { getModule } from 'vuex-module-decorators';
import { UserDto } from '@/api/types/api';
import { getSchoolPrefix } from '@/utils/common';

@Component({
  components: {
    GeneralLayout,
    GeneralHeader,
    EvaluationButton,
    AdminSnackbar,
  },
})
export default class ParentInfo extends Vue {
  userModule: UserModule = getModule(UserModule, this.$store);

  loading = false;

  // audios = ['/audio/intro.mp3', '/audio/show.mp3', '/audio/super.mp3'];

  get user(): UserDto {
    return this.userModule.currentUser;
  }

  get doneA(): boolean {
    return this.userModule.doneA.toString() === 'true';
  }

  get doneB(): boolean {
    return this.userModule.doneB.toString() === 'true';
  }

  get enabledA(): boolean {
    return this.userModule.enabledA.toString() === 'true';
  }

  get enabledB(): boolean {
    return this.userModule.enabledB.toString() === 'true';
  }

  get noTestEnabled(): boolean {
    return !this.enabledA && !this.enabledB;
  }

  get hasFinishedEnabledTest(): boolean {
    if (this.enabledA && this.enabledB) {
      return this.doneA && this.doneB;
    } else if (this.enabledA) {
      return this.doneA;
    } else {
      return this.doneB;
    }
  }

  get hasFinishedTests(): boolean {
    return Boolean(this.userModule.doneA && this.userModule.doneB);
  }

  goToStart(): void {
    this.$router.push({
      name: 'start',
      params: { schoolName: getSchoolPrefix() },
    });
  }

  async mounted() {
    // this.loading = true;
    // try {
    //   this.audios.forEach((audio) => {
    //     new Audio().src = audio;
    //   });
    // } catch {
    //   console.log('err: preloading audios');
    // } finally {
    //   this.loading = false;
    // }
  }
}
</script>

<style lang="scss" scoped>
#next-btn {
  height: 50px;
  p {
    color: $dark-blue !important;
  }
}

.parent {
  &__content {
    z-index: 150;
  }

  &__row {
    width: 100%;
  }
}

.cardinal-icon {
  margin-top: -2px;
}
</style>
