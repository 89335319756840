<template>
  <AdminLayout>
    <ResetPasswordForm />
  </AdminLayout>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import AdminLoginContainer from '../../components/admin/login/AdminLoginContainer.vue';
import ResetPasswordForm from '../../components/admin/login/ResetPasswordForm.vue';
import AdminLayout from '../../components/layout/AdminLayout.vue';

@Component({
  components: {
    AdminLoginContainer,
    AdminLayout,
    ResetPasswordForm,
  },
})
export default class ResetPassword extends Vue {}
</script>
