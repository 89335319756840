<template>
  <AdminLayout>
    <GroupTable />
  </AdminLayout>
</template>
  
<script lang="ts">
import GroupTable from '../../components/superadmin/GroupTable.vue'
import AdminLayout from '../../components/layout/AdminLayout.vue'
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {
    AdminLayout, GroupTable

  },
})
export default class Login extends Vue {

}
</script>