<template>
  <GeneralLayout>
    <template v-slot:header> <GeneralHeader /></template>
    <template v-slot:main> <CongratulationsContainer /></template>
  </GeneralLayout>
</template>

<script lang="ts">
import GeneralHeader from '../../components/base/GeneralHeader.vue';
import GeneralLayout from '../../components/layout/GeneralLayout.vue';
import CongratulationsContainer from '../../components/tests/overview/CongratulationsContainer.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import RiddleModule from '@/store/modules/RiddleModule';
import { getModule } from 'vuex-module-decorators';

@Component({
  components: {
    CongratulationsContainer,
    GeneralLayout,
    GeneralHeader,
  },
})
export default class Congratulations extends Vue {
  riddleModule: RiddleModule = getModule(RiddleModule, this.$store);

  mounted(): void {
    // const superAudio = new Audio('/audio/tutorials/u1/HELP3833.mp3');
    // superAudio.play();
    // const form = this.riddleModule.currentForm;
    // superAudio.addEventListener('ended', () => {
    //   setTimeout(async () => {
    //     router.push({ path: `/tests/${form}` });
    //   }, 1500);
    // });
  }
}
</script>
