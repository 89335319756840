<template>
  <GeneralLayout>
    <template v-slot:header>
      <GeneralHeader :title="title" :subtitle="subtitle"
    /></template>
    <template v-slot:main> <StudentLoginContainer /></template>
  </GeneralLayout>
</template>

<script lang="ts">
import FantasyModule from '@/store/modules/FantasyModule';
import LettersModule from '@/store/modules/LettersModule';
import RiddleModule from '@/store/modules/RiddleModule';
import SyllableModule from '@/store/modules/SyllableModule';
import WordsModule from '@/store/modules/WordsModule';
import { getSchoolPrefix } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import GeneralHeader from '../../components/base/GeneralHeader.vue';
import GeneralLayout from '../../components/layout/GeneralLayout.vue';
import StudentLoginContainer from '../../components/tests/login/StudentLoginContainer.vue';
import router from '../../router';

@Component({
  components: {
    StudentLoginContainer,
    GeneralLayout,
    GeneralHeader,
  },
})
export default class Login extends Vue {
  lettersModule: LettersModule = getModule(LettersModule, this.$store);
  fantasyModule: FantasyModule = getModule(FantasyModule, this.$store);
  syllableModule: SyllableModule = getModule(SyllableModule, this.$store);
  wordsModule: WordsModule = getModule(WordsModule, this.$store);
  riddleModule: RiddleModule = getModule(RiddleModule, this.$store);

  interval: any = null;

  get isChooseForm(): boolean {
    return this.$route.path === '/form';
  }

  get title(): string {
    return 'LEO';
  }

  get subtitle(): string {
    return 'Für lesestarke Kids!';
  }

  goBack(): void {
    router.push({
      name: 'login',
      replace: true,
      params: { schoolName: getSchoolPrefix() },
    });
  }

  async mounted() {
    this.lettersModule.reset();
    this.fantasyModule.reset();
    this.syllableModule.reset();
    this.wordsModule.reset();
    this.riddleModule.reset();

    // INFO check for service worker updates
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (registration) {
          registration.update();
          this.interval = setInterval(() => {
            registration.update();
          }, 60000 * 60); // check every 60 minutes
        }
      });
    }
  }

  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
</script>
