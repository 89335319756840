<template>
  <AdminLayout>
    <AdminLoginContainer />
  </AdminLayout>
</template>

<script lang="ts">
import AdminLayout from '../../components/layout/AdminLayout.vue';
import AdminLoginContainer from '../../components/admin/login/AdminLoginContainer.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import router from '../../router';
import { getSchoolPrefix } from '@/utils/common';

@Component({
  components: {
    AdminLoginContainer,
    AdminLayout,
  },
})
export default class Login extends Vue {
  get isChooseForm(): boolean {
    return this.$route.path === '/form';
  }

  get title(): string {
    return 'LEO';
  }

  get subtitle(): string {
    return 'Für lesestarke Kids!';
  }

  goBack(): void {
    router.push({
      name: 'login',
      replace: true,
      params: { schoolName: getSchoolPrefix() },
    });
  }

  //TODO enabling going to admin sites

  // mounted(): void {
  //   window.onpopstate = (event: { preventDefault: () => void }) => {
  //     event.preventDefault();
  //     router.push({ name: 'login', replace: true });
  //   };
  // }

  // beforeDestroy(): void {
  //   window.removeEventListener('popstate', this.goBack);
  // }
}
</script>
