<template>
  <AdminLayout>
    <ParentsDashboard />
  </AdminLayout>
</template>

<script lang="ts">
import ParentsDashboard from '../../components/admin/parents/ParentsDashboard.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import AdminLayout from '../../components/layout/AdminLayout.vue';

@Component({
  components: { AdminLayout, ParentsDashboard },
})
export default class ParentPage extends Vue {}
</script>
